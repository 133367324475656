<template>
  <div id="app" data-title="车世际">
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>
<script>
export default {
  name: "app",
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  mounted() {
    // var str = window.location.pathname.split("/")[1];
    // if (str == "" || str == "h5") {
    //   if (this._isMobile()) {
    //     // alert("手机端");
    //     this.$router.push({
    //       path: "/h5",
    //     });
    //   } else {
    //     // alert("pc端");
    //     this.$router.push({
    //       path: "/",
    //     });
    //   }
    // }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    isWeiXin() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.indexOf("micromessenger") > -1) {
        return true; // 是微信端
      } else {
        return false;
      }
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick((e) => {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.app {
  width: 100%;
  height: 100%;
}
</style>

<style lang="less" >
html,
body {
  height: 100%;
  width: 100%;
}
</style>
