import Vue from "vue";
import App from "./App.vue";
import router from "./router";

// 引入reset.css
import "@/assets/css/reset.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "swiper/css/swiper.css";
import axios from "axios";
// 引入vant
import Vant from "vant";
import "vant/lib/index.css";
import VueAwesomeSwiper from "vue-awesome-swiper";

Vue.use(VueAwesomeSwiper);

Vue.use(Vant);
Vue.use(ElementUI);
Vue.config.productionTip = false;
Vue.prototype.$http = axios;
Vue.config.ignoredElements = ["wx-open-launch-weapp"];
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
