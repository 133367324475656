<!--  -->
<template>
  <div class="content_css">
    <div class="head-css">
      <div class="left-cd">
        <img
          :src="log"
          class="img-responsive"
          alt="Cinque Terre"
          width="151"
          height="48"
        />
      </div>
      <div class="right_cd">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="首页" name="1"></el-tab-pane>
          <!-- <el-tab-pane label="招商" name="9"></el-tab-pane> -->
          <el-tab-pane label="停驶无忧" name="2"></el-tab-pane>
          <el-tab-pane label="停车赚绿码" name="3"></el-tab-pane>
          <el-tab-pane label="平台业务" name="4"></el-tab-pane>
          <el-tab-pane label="商学院" name="5"></el-tab-pane>
          <el-tab-pane label="市场动态" name="6"></el-tab-pane>
          <el-tab-pane label="操作手册" name="7"></el-tab-pane>
          <el-tab-pane label="关于我们" name="8"></el-tab-pane>
        </el-tabs>
        <!-- <div class="eg_rigf">
          <div class="erg_huwf">进入工作台</div>
        </div> -->
      </div>
    </div>
    <div class="g_hugf" v-if="activeName != 9">
      <div
        id="myCarousel"
        class="carousel slide"
        data-ride="carousel"
        data-interval="2000"
      >
        <div class="carousel-inner">
          <div
            class="item"
            :class="index == 0 ? 'active' : ''"
            v-for="(item, index) in banners"
            :key="index"
          >
            <img :src="item" alt="First slide" />
          </div>
          <!-- <div class="item">
            <img src="../image/banner/test1.png" alt="First slide" />
          </div>
          <div class="item">
            <img src="../image/banner/test2.png" alt="First slide" />
          </div>
           <div class="item">
            <img src="../image/banner/test3.png" alt="First slide" />
          </div> -->
        </div>
      </div>
      <div class="g_hygyfwe">
        <div class="g_hygw">
          <img src="../image/nnuwd.png" alt="" />
        </div>
        <div id="box">
          <ul id="con1" ref="con1" :class="{ anim: animate == true }">
            <li v-for="item in items">{{ item.name }}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="rg_uuwf">
      <!-- 首页 -->
      <homePage v-if="activeName == 1"></homePage>
      <!-- 停驶无忧 -->
      <stopDriving v-if="activeName == 2"></stopDriving>
      <!-- 停车赚绿码 -->
      <greenCode v-if="activeName == 3"></greenCode>
      <!-- 平台业务 -->
      <platformBusiness v-if="activeName == 4"></platformBusiness>
      <!-- 商学院 -->
      <businessSchool v-if="activeName == 5"></businessSchool>
      <!-- 市场动态 -->
      <marketDynamics v-if="activeName == 6"></marketDynamics>
      <!-- 操作手册 -->
      <operationManual v-if="activeName == 7"></operationManual>
      <!-- 关于我们 -->
      <aboutUs v-if="activeName == 8"></aboutUs>
      <!-- 招商 -->
      <attractInvestment v-if="activeName == 9"></attractInvestment>
    </div>
    <div class="float_banner" v-if="activeName != 9">
      <a class="float_banner_item hefucs" href="">
        <img src="../image/float_banner/kefu.png" />
        <div style="margin-top: 5px">客服</div>
      </a>
      <a class="float_banner_item gongzhonghao" href="#">
        <img src="../image/float_banner/erwma.png" />
        <div style="margin-top: 5px">公众号</div>
      </a>
      <a class="float_banner_item xiaochengx" href="#">
        <img src="../image/float_banner/xiaochengxu_1.png" />
        <div style="margin-top: 5px">小程序</div>
      </a>
      <a
        @click="scroll2Top"
        class="float_banner_item"
        href="javascript:void(0)"
      >
        <img src="../image/float_banner/to_top.png" />
        <div style="margin-top: 5px">回到顶部</div>
      </a>
    </div>
    <div class="bottom-css" v-if="activeName != 9">
      <div class="erg_gywf">
        <div class="title">打造本地化用车服务平台</div>
        <div class="sub_title">买车、租车、洗车一站式服务</div>
        <div class="qrcode">
          <div>
            <img src="../image/float_banner/gongzhonghao.png" />
            <div style="margin-top: 5px; font-size: 20px">车世际公众号</div>
          </div>
          <div style="margin-left: 25px">
            <img src="../image/float_banner/xiaochengxu.png" />
            <div style="margin-top: 5px; font-size: 20px">车世际小程序</div>
          </div>
        </div>
      </div>
      <div
        style="
          background: #272727;
          height: 483px;
          padding: 60px 142px 53px 142px;
        "
      >
        <div class="youqing">友情连接:车世际</div>
        <div class="eg_uuw">
          <div class="wd_juiwf">
            <span class="eg_huwf">停驶无忧</span>
          </div>
          <div class="wd_juiwf">
            <span class="eg_huwf">停驶赚绿码</span>
          </div>
          <div class="wd_juiwf">
            <span class="eg_huwf">业务平台</span>
          </div>
          <div class="wd_juiwf">
            <span class="eg_huwf">商学院</span>
          </div>
          <div class="wd_juiwf">
            <span class="eg_huwf">市场动态</span>
          </div>
          <div class="wd_juiwf">
            <span class="eg_huwf">操作手册</span>
          </div>
          <div class="wd_juiwf">
            <span class="eg_huwf">关于我们</span>
          </div>
        </div>
        <div class="iwd_uihwf">
          <div class="uhuwf_name">
            <div class="eg_ugw">热线客服</div>
            <div class="wif_nwf">028-84391136</div>
          </div>
          <div class="uhuwf_name">
            <div class="eg_ugw">商务合作</div>
            <div class="wif_nwf">028-85984189</div>
          </div>
          <div class="uhuwf_name">
            <div class="eg_ugw">邮件客服</div>
            <div class="wif_nwf">3470704452@qq.com</div>
          </div>
        </div>
        <div class="beihanhao_na">
          <div class="eg_ugwfs">
            COPYRIGHT © 四川车世际科技有限公司
            成都市武侯区泰然环球时代中心C座806
          </div>
          <div class="eg_ugwfs" @click="onClickbeian">
            版权所有Copyright 四川车世际科技有限公司 蜀ICP备{{beianhao}}
          </div>
        </div>
      </div>
      <img
        src="../image/float_banner/Grey.png"
        class="img-responsive ihfe_img"
        alt="Cinque Terre"
      />
    </div>
  </div>
</template>

<script>
import config from "../config";
const homePage = () => import("./officialWebsite/homePage/homePage.vue");
const stopDriving = () => import("./officialWebsite/homePage/stopDriving.vue");
const greenCode = () => import("./officialWebsite/homePage/greenCode.vue");
const platformBusiness = () =>
  import("./officialWebsite/homePage/platformBusiness.vue");
const businessSchool = () =>
  import("./officialWebsite/homePage/businessSchool.vue");
const marketDynamics = () =>
  import("./officialWebsite/homePage/marketDynamics.vue");
const operationManual = () =>
  import("./officialWebsite/homePage/operationManual.vue");
const aboutUs = () => import("./officialWebsite/homePage/aboutUs.vue");
const attractInvestment = () =>
  import("./officialWebsite/homePage/attractInvestment.vue");
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    homePage,
    stopDriving,
    greenCode,
    platformBusiness,
    businessSchool,
    marketDynamics,
    operationManual,
    aboutUs,
    attractInvestment,
  },
  data() {
    return {
      log: `${config.Url}guanwangcheshijilogo.png`,
      activeName: "1",
      banners: [
        `${config.Url}guanwanglunbotest.png`,
        `${config.Url}guanwangluntest1.png`,
        `${config.Url}guanwanglunbotest2.png`,
        `${config.Url}guangwanglunbotest3.png`,
      ],
      items: [
        { name: "已有109207人注册!" },
        { name: "车世际1元洗车开启啦!" },
        { name: "车世际购买洗车套餐送688红酒!" },
      ],
      animate: false,
      beianhao:"2021027402号"
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    onClickbeian() {
      window.location.href = "https://beian.miit.gov.cn";
    },
    scroll() {
      this.animate = true; // 因为在消息向上滚动的时候需要添加css3过渡动画，所以这里需要设置true
      setTimeout(() => {
        //  这里直接使用了es6的箭头函数，省去了处理this指向偏移问题，代码也比之前简化了很多
        this.items.push(this.items[0]); // 将数组的第一个元素添加到数组的
        this.items.shift(); //删除数组的第一个元素
        this.animate = false; // margin-top 为0 的时候取消过渡动画，实现无缝滚动
      }, 500);
    },
    handleClick() {
      this.scroll2Top();
    },
    scroll2Top() {
      window.scrollTo(0, -100);
      var scrollDelay = setTimeout("scroll2Top()", 100);
      var sTop = document.documentElement.scrollTop + document.body.scrollTop;
      if (sTop == 0) {
        clearTimeout(scrollDelay);
      }
    },
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    console.log(window.location.origin)
    if (window.location.origin == "http://www.cheshiji.com.cn") {
      this.beianhao = "2021013738号-1";
    } else {
      this.beianhao = "2021027402号";
    }
    setInterval(this.scroll, 1000);
  },
};
</script>
<style scoped>
/* @import url(); 引入css类 */
* {
  margin: 0;
  padding: 0;
}
.content_css {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.head-css {
  width: 100%;
  height: 90px;
  position: fixed;
  top: 0;
  left: 0;
  background: #ffffff;
  z-index: 999;
  display: flex;
}
.g_hugf {
  height: auto;
  position: relative;
}
#box {
  width: 60%;
  height: 54px;
  overflow: hidden;
  padding-left: 5px;
  background: #ffffff;
}
.anim {
  transition: all 0.5s;
  margin-top: -54px;
}
#con1 li {
  list-style: none;
  line-height: 54px;
  height: 54px;
  color: #272727;
}
.g_hygyfwe {
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}
.g_hygw {
  background: #ffffff;
  height: 54px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.g_hugf /deep/ .el-carousel__container {
  height: 941px;
}
.left-cd {
  width: 45%;
  display: flex;
  justify-content: center;
  height: 90px;
  align-items: center;
}
.right_cd {
  width: 55%;
  height: 90px;
  display: flex;
}
.right_cd /deep/ .is-top .el-tabs__nav-scroll {
  margin: 0;
  height: 90px;
}
.right_cd /deep/.el-tabs__nav-wrap::after {
  height: 0px;
}
.right_cd /deep/ .el-tabs__active-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 2px;
  background-color: #ff6600;
  z-index: 1;
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  list-style: none;
  width: 90px;
}
.right_cd /deep/ .el-tabs__item {
  width: 90px;
  height: 90px;
  box-sizing: border-box;
  line-height: 90px;
  display: inline-block;
  list-style: none;
  font-size: 16px;
  font-weight: 500;
  color: #272727;
  position: relative;
  padding: 0;
  text-align: center;
  font-family: Source Han Sans CN;
}
.right_cd /deep/.el-tabs__item.is-active {
  color: #ff6600;
  background: rgba(255, 102, 0, 0.08);
}
.eg_rigf {
  display: flex;
  height: 90rpx;
  align-items: center;
  margin-left: 20px;
}
.rg_uuwf {
  position: relative;
}
.erg_huwf {
  width: 101px;
  height: 33px;
  background: #ff6600;
  opacity: 1;
  border-radius: 31px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 33px;
  text-align: center;
  color: #ffffff;
}
.float_banner {
  position: fixed;
  background: #ff6600;
  height: 300px;
  top: 250px;
  display: flex;
  right: 0;
  z-index: 999;
  flex-flow: column;
  display: flex;
  text-align: center;
  width: 80px;
}

.float_banner_item {
  align-content: center;
  text-decoration: none;
  font-size: 10px;
  text-align: center;
  padding-top: 5px;
  color: white;
  flex: 1;
  margin: 5px;
}

.title {
  padding-left: 260px;
  font-family: sans-serif;
  font-size: 50px;
  font-weight: bold;
  color: #272727;
  opacity: 1;
}

.sub_title {
  padding-left: 340px;
  padding-top: 15px;
  font-family: sans-serif;
  font-size: 26px;
  color: #272727;
  opacity: 1;
}
.qrcode {
  margin-top: 40px;
  margin-left: 340px;
  text-align: center;
  width: 200px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}
.bottom-css {
  position: relative;
}
.ihfe_img {
  position: absolute;
  right: 142px;
  top: 120px;
}
.erg_gywf {
  height: 736px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.youqing {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 27px;
  color: #ffffff;
}
.eg_uuw {
  padding-top: 80px;
  display: flex;
  align-items: center;
}
.eg_huwf {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 27px;
  color: rgba(255, 255, 255, 0.34);
}
.eg_huwf:hover {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 27px;
  color: #ff6600;
}
.wd_juiwf {
  padding-right: 5%;
  cursor: pointer;
}
.iwd_uihwf {
  padding-top: 60px;
  padding-bottom: 64px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  display: flex;
}
.carousel-indicators {
  width: 20%;
  left: 0;
  bottom: 30%;
}
.wif_nwf {
  font-size: 24px;
  font-family: DIN;
  font-weight: 400;
  line-height: 29px;
  color: #ffffff;
}
.eg_ugw {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 27px;
  color: #ffffff;
}
.uhuwf_name {
  padding-right: 12%;
}
.beihanhao_na {
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
}
.eg_ugwfs {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 27px;
  color: #ffffff;
}
.gongzhonghao:hover::after {
  background-image: url("https://scqc-obs.obs.cn-south-1.myhuaweicloud.com/cutimg/gongzhonghao.png");
  content: "";
  width: 120px;
  height: 120px;
  background-size: 100% auto;
  position: absolute;
  top: 50px;
  left: -120px;
}
.xiaochengx:hover::after {
  background-image: url("https://scqc-obs.obs.cn-south-1.myhuaweicloud.com/cutimg/xiaochengxu.png");
  content: "";
  width: 120px;
  height: 120px;
  background-size: 100% auto;
  position: absolute;
  top: 120px;
  left: -120px;
}
.hefucs:hover::after {
  background-image: url("https://scqc-obs.obs.cn-south-1.myhuaweicloud.com/cutimg/dianhuahaoma.png");
  content: "";
  width: 120px;
  height: 50px;
  background-size: 100% auto;
  position: absolute;
  top: 15px;
  left: -120px;
}
</style>