import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

// const routes = [];
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "四川车世际科技有限公司",
      component: Home,
      meta: {
        title: "四川车世际科技有限公司",
      },
    },
    {
      path: "/h5",
      name: "四川车世际科技有限公司",
      component: () => import("../views/h5"),
      meta: {
        title: "四川车世际科技有限公司",
      },
    },
    {
      path: "/active/Invitefriends",
      name: "邀请好友",
      component: () => import("../views/active/invite_users.vue"),
      meta: {
        title: "好友邀请",
      },
    },
    {
      path: "/active/downloadApp",
      name: "下载App",
      component: () => import("../views/active/downloadApp.vue"),
      meta: {
        title: "下载App",
      },
    },
    {
      path: "/active/getaCoupon_active",
      name: "领取优惠卷",
      component: () => import("../views/active/getaCoupon_active.vue"),
      meta: {
        title: "领取优惠卷",
      },
    },
    {
      path: "/active/lineApp",
      name: "下载app",
      component: () => import("../views/active/lineApp.vue"),
      meta: {
        title: "车实际",
      },
    },
    {
      path: "/active/promoter",
      name: "欢迎加入推广者",
      component: () => import("../views/active/promoter.vue"),
      meta: {
        title: "欢迎加入推广者",
      },
    },
    {
      path: "/active/cashRedEnvelope",
      name: "更多优惠券等你来领",
      component: () => import("../views/active/cashRedEnvelope.vue"),
      meta: {
        title: "更多优惠券等你来领",
      },
    },

    {
      path: "/newCustomers/Smash",
      name: "砸",
      component: () => import("../views/newCustomers/Smash.vue"),
    },
    {
      path: "/active/recommendedAward",
      name: "推荐有奖",
      component: () => import("../views/active/recommendedAward.vue"),
    },
    {
      path:'/active/whiteQuestionsAndAnswers',
      name:'百问百答',
      component:()=>import('../views/active/whiteQuestionsAndAnswers'),
      meta:{
        title:'百问百答'
      }
    },
    {
      path: "/active/dome",
      name: "测试",
      component: () => import("../views/active/dome.vue"),
    },
    {
      path: "/active/IntroductionAndDescription",
      name: "介绍与说明",
      component: () => import("../views/active/IntroductionAndDescription.vue"),
      meta: {
        title: "介绍与说明",
      },
    },
    {
      path: "/Introduction/processOne",
      nmae: "商家注册步骤",
      component: () => import("../views/Introduction/processOne"),
      meta: {
        title: "商家注册步骤",
      },
    },
    {
      path: "/Introduction/processTwo",
      nmae: "卖车流程",
      component: () => import("../views/Introduction/processTwo"),
      meta: {
        title: "卖车流程",
      },
    },
    {
      path: "/Introduction/processThree",
      nmae: "买车流程",
      component: () => import("../views/Introduction/processThree"),
      meta: {
        title: "买车流程",
      },
    },
    {
      path: "/Introduction/processFour",
      name: "租车流程",
      component: () => import("../views/Introduction/processFour"),
      meta: {
        title: "租车流程",
      },
    },
    {
      path: "/Introduction/processFive",
      name: "商机客户管理",
      component: () => import("../views/Introduction/processFive"),
      meta: {
        title: "商机客户管理",
      },
    },
    {
      path: "/Introduction/processSix",
      name: "商家好友管理",
      component: () => import("../views/Introduction/processSix"),
      meta: {
        title: "商家好友管理",
      },
    },
    {
      path: "/Introduction/processSeven",
      name: "核销手机管理",
      component: () => import("../views/Introduction/processSeven"),
      meta: {
        title: "核销手机管理",
      },
    },
    {
      path: "/Introduction/processEight",
      name: "保养商品上架流程",
      component: () => import("../views/Introduction/processEight"),
      meta: {
        title: "保养商品上架流程",
      },
    },
    {
      path: "/Introduction/processNine",
      name: "保养商品购买流程",
      component: () => import("../views/Introduction/processNine"),
      meta: {
        title: "保养商品购买流程",
      },
    },
    {
      path: "/Introduction/processTen",
      name: "优惠券制作与发布",
      component: () => import("../views/Introduction/processTen"),
      meta: {
        title: "优惠券制作与发布",
      },
    },
    {
      path: "/Introduction/processEleven",
      name: "托管业务办理流程",
      component: () => import("../views/Introduction/processEleven"),
      meta: {
        title: "托管业务办理流程",
      },
    },
    {
      path: "/Introduction/processTwelve",
      name: "停驶流程",
      component: () => import("../views/Introduction/processTwelve"),
      meta: {
        title: "停驶流程",
      },
    },
    {
      path: "/Introduction/processThirteen",
      name: "停驶无忧流程",
      component: () => import("../views/Introduction/processThirteen"),
      meta: {
        title: "停驶无忧流程",
      },
    },
    {
      path: "/Introduction/processFourteen",
      name: "积分商城介绍",
      component: () => import("../views/Introduction/processFourteen"),
      meta: {
        title: "积分商城介绍",
      },
    },
    {
      path: "/Introduction/processTwenty",
      name: "车辆短期无押金租聘(乘客端)",
      component: () => import("../views/Introduction/processTwenty"),
      meta: {
        title: "车辆短期无押金租聘(乘客端)",
      },
    },
    {
      path: "/Introduction/processFifteen",
      name: "个人资料编辑",
      component: () => import("../views/Introduction/processFifteen"),
      meta: {
        title: "个人资料编辑",
      },
    },
    {
      path: "/Introduction/processSixteen",
      name: "银行卡管理",
      component: () => import("../views/Introduction/processSixteen"),
      meta: {
        title: "银行卡管理",
      },
    },
    {
      path: "/Introduction/processSeventeen",
      name: "托管收益介绍",
      component: () => import("../views/Introduction/processSeventeen"),
      meta: {
        title: "托管收益介绍",
      },
    },
    {
      path: "/Introduction/processEighteen",
      name: "金币介绍",
      component: () => import("../views/Introduction/processEighteen"),
      meta: {
        title: "金币介绍",
      },
    },
    {
      path: "/Introduction/processNineteen",
      name: "领取及使用优惠券",
      component: () => import("../views/Introduction/processNineteen"),
      meta: {
        title: "领取及使用优惠券",
      },
    },
  ],
});

export default router;
